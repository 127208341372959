import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { RequestType, geocode } from "react-geocode";

const RiderTripConfirmation = ({ tripRequest }) => {
  const [pickupLocation, setPickupLocation] = useState({
    lat: 39.1653,
    lng: -86.526,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyApLH-dlfaVqrVuzhk3VvQGsml01ZucRTQ",
  });

  const getCoordinates = (value) => {
    try {
      geocode(RequestType.ADDRESS, value).then(({ results }) => {
        setPickupLocation({
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng,
        });
      });
    } catch {
      console.log("Error with geolocation");
    }
  };

  useEffect(() => { getCoordinates(tripRequest.pickup); }, []);

  return (
    <div>
      <div className="split-body-equal-left-unpadded">
        <div>
          <p style={{ fontSize: 24, fontWeight: 600 }}>Confirm Your Trip</p>
          <br></br>
          <br />
          <div>
            <div>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>Pickup point</p>
              <br></br>
              <p>{tripRequest.pickup}</p>
              <br></br>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "10%" }}>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Nearby Intersection
                </p>
                <br></br>
                <p>{tripRequest.nearbyIntersection}</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Destination address
                </p>
                <br></br>
                <p>{tripRequest.destination}</p>
                <br></br>
              </div>

            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "20%" }}>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Date of trip
                </p>
                <br></br>
                <p>{tripRequest.date.slice(0, 10)}</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Pickup time
                </p>
                <br></br>
                <p>{tripRequest.date.slice(11,16)}</p>
                <br></br>
              </div>
              {/* <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Appointment time
                </p>
                <br></br>
                <p>{tripRequest.appointmentTime}</p>
                <br></br>
              </div> */}
            </div>

            <div className="form-row">
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Total wait time
                </p>
                <br></br>
                <p>{tripRequest.waitTime}</p>
                <br></br>
              </div>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Stops during the trip
                </p>
                <br></br>
                <p>{tripRequest.numberOfStops}</p>
                <br></br>
              </div>
            </div>

            <div>
              {tripRequest.stopPoints.map((_, index) => (
                <div className="form-row" key={index}>
                  <div>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>Stop address</p>
                    <br></br>
                    <p>{tripRequest.stopPoints[index]}</p>
                    <br></br>
                  </div>
                  <div>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Stop wait time
                    </p>
                    <br></br>
                    <p>{tripRequest.stopWaitTimes[index]}</p>
                    <br></br>
                  </div>
                </div>
              ))}
            </div>


          </div>

          <br></br>
          <br />
        </div>

        <div>
          <p style={{ fontSize: 24, fontWeight: 600 }}>Map</p>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              center={pickupLocation}
              zoom={10}
            >
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};

export default RiderTripConfirmation;
